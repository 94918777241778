.channel-search__result {
  display: flex;
  padding: 2px 4px;
  cursor: pointer;
  width: 100%;
  border-radius: var(--border-radius-md);
  margin-bottom: 4px;
}

.channel-search__result:hover {
  background: var(--white);
  font-weight: var(--font-weight-bold);
  transition: all .2s ease-in-out;
}

.channel-search__result-text {
  margin: 0;
}

.channel-search__result-info {
  width: 85%;
  margin-left: 4px;
}

.channel-search__result-message {
  font-family: Helvetica Neue, sans-serif;
  font-size: 10px;
  line-height: 12px;
  margin: 0;
  padding-top: 4px;
  color: #858688;
  height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
