.channel-preview__container {
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
}

.channel-preview__container:hover {
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07);
  transition: background 0.1s ease-in-out;
}

.str-chat.dark .channel-preview__container:hover {
  background: #2c2c30;
}

.channel-preview__container.selected {
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  transition: background 0.1s ease-in-out;
}

.str-chat.dark .channel-preview__container.selected {
  background: #2c2c30;
}

.channel-preview__content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  width: 100%;
}

.channel-preview__content-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  height: 18px;
  margin-bottom: 4px;
}

.channel-preview__content-bottom {
  font-size: 12px;
  color: #FF0000;
  font-weight: bold;
}

.channel-preview__content-status {
  display: flex;
}

.channel-preview__content-name {
  font-family: helvetica neue, sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #858688;
  margin: 0;
  max-width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.channel-preview__content-name.unread {
  font-weight: 600;
  color: #000000;
}

.str-chat.dark .channel-preview__content-name {
  color: #ffffff;
}

.channel-preview__content-badge {
  margin: 0 0 0 4px;
  background-color: red;
  color: white;
  font-weight: bold;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  text-align: center;
  line-height: 16px;
  font-size: 12px;
}

.channel-preview__container .str-chat__avatar {
  margin-right: 0;
}

.channel-preview__content-time {
  font-family: Helvetica Neue, sans-serif;
  font-size: 11px;
  color: #858688;
  margin: 0 4px 0 0;
}

.channel-preview__details {
  display: flex;
  align-items: center
}
