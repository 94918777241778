.login__landing-wrapper {
  width: 100%;
  height: 100%;
  background-color: #F0F2F5;
}

.login__landing-wrapper-before {
  position: absolute;
  top: 0;
  width: 100%;
  height: 222px;
  background-color: #06adb6;
}

.login__landing-wrapper-header {
  margin: 27px auto 28px;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: flex-start;
  width: 1000px;
  min-height: 39px;
  position: relative;
}

.login__landing-headerTitle {
  display: inline-block;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  color: #fff;
  text-transform: uppercase;
  vertical-align: middle;
}

.login__landing-window {
  display: flex;
  justify-content: center;
  position: relative;
  width: 1000px;
  margin: auto;
  padding: 32px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 17px 50px 0 rgb(11 20 26 / 19%), 0 12px 15px 0 rgb(11 20 26 / 24%);
}

.login__landing-title {
  margin-bottom: 52px;
  margin-left: 1rem;
  font-size: 28px;
  font-weight: 300;
  line-height: normal;
  color: #41525d;
}

.login__landing-list {
  margin-bottom: 18px;
  font-size: 18px;
  line-height: 28px;
  color: #3b4a54;
}
