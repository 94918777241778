.messaging__channel-header {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.03), 0px 1px 0px rgba(0, 0, 0, 0.03);
  transform: matrix(1, 0, 0, 1, 0, 0);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
}

.messaging__channel-header .channel-header__name {
  margin-left: 80px;
  flex: 1;
  font-weight: bold;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.9);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.messaging__channel-header .channel-header__link {
  margin-right: 40px;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
}

.str-chat.dark .messaging__channel-header {
  background: rgba(46, 48, 51, 0.98);
  box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.03), 0px 1px 0px rgba(0, 0, 0, 0.03);
}

.str-chat.dark .messaging__channel-header .channel-header__name {
  color: rgba(255, 255, 255, 0.9);
}

.messaging__channel-header__right {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.messaging__channel-header .avatar-group__avatars {
  margin-right: 20px;
  margin-left: 20px;
}

.messaging__channel-header .avatar-group__avatars.four span:nth-child(2) {
  position: relative;
  right: 9px;
}

.channel-header__edit-input {
  width: 100%;
  font-family: Helvetica Neue, sans-serif;
  font-size: 17px;
  background: none;
  outline: none;
  border: none;
  padding: 0;
}

.channel-header__edit-input::placeholder {
  opacity: 50%;
}

.str-chat.dark .channel-header__edit-input::placeholder {
  color: #ffffff;
}

.str-chat.dark .channel-header__edit-input {
  color: #ffffff;
}

#mobile-nav-icon {
  display: none;
}

@media screen and (max-width: 640px) {
  #mobile-nav-icon {
    display: block;
    padding-left: 5px;
  }

  #mobile-nav-icon.light svg path {
    fill: darkslategrey;
    stroke: darkslategrey;
    fill-opacity: 60%;
  }

  .messaging__channel-header__avatars {
    margin-left: 10px;
  }
}

.messaging__channel-header__button {
  font-size: 13px;
  color: #858688;
  background-color: #fafafa;
  padding: 8px;
  border-radius: 4px;
  margin-left: 8px;
  cursor: pointer;
}

.messaging__channel__modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
}

.messaging__channel__modal__container {
  background: white;
  padding: 24px;
  list-style: none;
  border-radius: 4px;
  margin: 0;
}

.messaging__channel__modal__link {
  cursor: pointer;
  margin: 8px 0;
  padding: 4px;
}

.messaging__channel__modal__link:hover {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07);
  transition: background 0.1s ease-in-out;
}
