.str-chat__channel-search {
  display: flex;
  flex-direction: column;
  padding: 4px 8px;
  width: calc(100vw - 350px);
  background: #fff;
}

.str-chat__channel-search input {
  width: 100%;
  padding: 8px 16px;
}

.str-chat__channel-search-container.inline {
  height: auto;
  position: fixed;
  top: 43px;
  left: 350px;
  width: 30%;
  padding: 16px;
  margin-top: 1px;
  border-radius: 4px;
}

.str-chat__channel-list {
  max-width: 350px;
}

.messaging__main__search .str-chat__channel-list {
  max-width: 100%;
}

.str-chat__channel-list__section-name {
  margin: 0 8px;
  color: #858688;
  font-family: helvetica neue, sans-serif;
  font-weight: 400;
  font-size: 15px;
}

.str-chat__channel-list__section-name.unread {
  color: #000000;
  font-weight: 600;
}
