.avatar-group__avatars {
  display: flex;
  align-items: center;
  height: 30px;
  min-width: 30px;
  max-width: 30px;
  border-radius: 20px;
  overflow: hidden;
}

.avatar-group__avatars.two div:first-child {
  position: relative;
  right: 7.5px;
}

.avatar-group__avatars.two div:nth-child(2) {
  position: relative;
  right: 22.5px;
}

.avatar-group__avatars.two span {
  width: 15px;
  overflow: hidden;
}

.avatar-group__avatars.three span {
  width: 15px;
  overflow: hidden;
}
